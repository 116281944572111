.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 18px;
    row-gap: 20px;
}

.recommendMassage {
    font-family: var(--arm-font);
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000000;
    padding: 10px;
    margin-right: 10em;
}

.redirectBtn {
    background: #2A6D88;
    border-radius: 5px;

    height: 48px;
    max-width: 160px;

    font-family: var(--arm-font);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
}