.secondaryFormWrapper {
    width: 50%;
    min-height: 100vh;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #C1FBFF;
}

@media (max-width: 960px) {
    .secondaryFormWrapper {
        width: 100%;
    }
}