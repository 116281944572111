.wrapper {
    margin-bottom: 9px;
}

.inputWrapper {
    width: 100%;
}

.input::placeholder {
    color:#161616;
    opacity: 0.5;
}

input:focus { border-color: black !important }
.inputError { border: 1px solid red!important }

.label {
    color: #000000;
    padding-bottom: 8px;
    display: flex;
    font-family: var(--arm-font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.errorMassage {
    color: #ee3333;
    padding-top: 8px;
    margin: 0;
}