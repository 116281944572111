.wrapper {
    height: 100vh;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.collageBody {
    height: 80%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}


.logoWrapper {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.collage {
    width: 100%;
    height: 80%;
}
.collage > img {
    width: 100%;
    height: 100%;
}

@media (max-width: 960px) {
    .wrapper {
        pointer-events: none;
        position: fixed;
        height: 100vh;
        width: 100vw;
        top: 0;
    }
    .collageBody {
        width: 90%;
        height: 100%;
    }
    .collage > img {
        opacity: 5%;
    }
    .logoWrapper {
        padding: 20px;
        opacity: 8%;
        margin-bottom: 20px;
    }
}