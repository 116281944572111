.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.formBody {
    width: 80%;
    height: 90%;
    padding: 20px 0;
    z-index: 1;
}


.titleWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
}

.titleWrapper > h3 {
    max-width: 90%;
    margin: 0;
    font-family: var(--arm-font);
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/*.titleWrapper > h3 > mark {*/
/*    font-weight: 800;*/
/*    background: none;*/
/*    color: #393939;*/
/*    padding: 0 4px;*/
/*    font-family: var(--en-font);*/
/*    text-align: center;*/
/*}*/

/*.titleWrapper > li {*/
/*    font-size: 16px;*/
/*    color: #393939;*/
/*    list-style: none;*/
/*    padding: 8px 6px;*/
/*}*/
/*.titleWrapper > li > mark {*/
/*    background: none;*/
/*    color: #393939;*/
/*    font-weight: 800;*/
/*    font-family: Montserrat, serif;*/
/*}*/

.inputWrapper {
    width: 100%;
    max-width: 480px;
}
.formInput {
    box-sizing: border-box;
    height: 48px;
    background-color: #FFFFFF;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    width: 100%;
    padding: 14px 16px;
    color: rgb(22,22,22,50%);

    font-family: var(--arm-font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.btnWrapper {
    margin-top: 32px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.submitBtn {
    background: #2A6D88;
    border-radius: 5px;

    height: 48px;
    min-width: 160px;

    font-family: var(--arm-font);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;
}