@font-face {
    font-family: 'Noto Sans Armenian';
    src: url('./NotoSansArmenian-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

:root {
    --arm-font: 'Noto Sans Armenian', serif;
    --en-font: Montserrat, serif;
}